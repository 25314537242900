<template>
  <Toast />
  <ul class="clase-breadcrumb">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Inventario/Kardex</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Kardex de Productos</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <h4><strong>KARDEX GENERAL DE PRODUCTOS</strong></h4>
    </div>
    <div class="col-12">
      <div class="card p-fondo">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-3">
            <span><strong>SUCURSAL: </strong></span>
            <Dropdown
              v-model="sucursalSelected"
              :options="sucursales"
              optionLabel="nombre"
              optionValue="id"
            />
          </div>
          <div class="field col-12 md:col-2">
            <span><strong>C&Oacute;D. DE BARRAS: </strong></span>
            <InputText
              id="codigo_producto"
              v-model="codigo_producto"
              placeholder="Código de producto"
              class="text-right"
              @keyup.enter="BuscarFiltro"
            />
          </div>
          <div class="field col-12 md:col-7">
            <span><strong>NOMBRE DE PRODUCTO</strong></span>
            <AutoComplete
              :dropdown="true"
              field="descripcion"
              v-model="productoSelected"
              :suggestions="productosListadoFiltrado"
              @complete="buscarProducto($event)"
              placeholder="Escriba nombre del producto"
            >
              <template #item="slotProps">
                <div>
                  {{ slotProps.item.descripcion }} **
                  {{ slotProps.item.fabrica_nombre }} **
                  <!-- <span v-if="sucursales.length > 2">
                    {{ slotProps.item.stock[0].nombre_sucursal }}
                    <span><strong> ** </strong></span>
                  </span> -->
                  <span
                    :class="
                      stockClass(
                        slotProps.item.stock.length > 0
                          ? slotProps.item.stock[0].cantidad
                          : 0
                      )
                    "
                    ><strong>{{
                      slotProps.item.stock.length > 0
                        ? slotProps.item.stock[0].cantidad
                        : 0
                    }}</strong></span
                  >
                </div>
              </template>
            </AutoComplete>
          </div>
          <div class="field col-12 md:col-2">
            <span><strong>FECHA INICIO: </strong></span>
            <Calendar
              id="fecha_inicio"
              v-model="fecha_inicio"
              :showIcon="true"
              dateFormat="dd/mm/yy"
            />
          </div>
          <div class="field col-12 md:col-2">
            <span><strong>FECHA FIN: </strong></span>
            <Calendar
              id="fecha_fin"
              v-model="fecha_fin"
              :showIcon="true"
              dateFormat="dd/mm/yy"
            />
          </div>

          <div class="field col-12 md:col-3">
            <span><strong>ACCIONES: </strong></span>
            <Button
              label="APLICAR FILTROS"
              icon="pi pi-search"
              class="p-button-info"
              :loading="enviando"
              :disabled="enviando"
              @click="BuscarFiltro"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <DataTable
        ref="dtKardex"
        key="id"
        :value="kardexs"
        responsiveLayout="scroll"
        :loading="enviando"
        class="p-datatable-sm white-space-nowrap"
        :filters="buscar"
        responsive="true"
        stripedRows
        showGridlines
      >
        <template #header>
          <div
            class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
          >
            <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
              <i class="pi pi-search" />
              <InputText
                v-model="buscar['global'].value"
                placeholder="Buscar Kardex..."
              />
            </span>
            <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
              <Button
                label="PDF Kardex"
                type="button"
                icon="pi pi-file-pdf"
                class="p-button-outlined p-button-danger"
                v-tooltip.top="'Exportar PDF Kardexs'"
                :loading="generando_pdf"
                :disabled="generando_pdf"
                @click="generarPDF()"
              />
            </span>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <Button
                label="Excel Kardex"
                type="button"
                icon="pi pi-file-excel"
                class="p-button-outlined p-button-success"
                v-tooltip.top="'Exportar Excel Kardexs'"
                :loading="exportando"
                :disabled="exportando"
                @click="exportKardexs()"
              />
            </span>
          </div>
        </template>
        <template #loading
          ><div class="flex align-items-center justify-content-center">
            <ProgressSpinner /></div
        ></template>
        <template #empty>
          <span class="p-invalid">
            Debe aplicar filtros para ver resultados en la tabla!</span
          >
        </template>
        <Column field="codigo_producto" header="COD.P"></Column>
        <Column
          field="nombre_sucursal"
          header="SUCURSAL"
          v-if="sucursales.length > 2"
        ></Column>
        <Column field="fecha" header="FECHA" :sortable="true"></Column>
        <Column field="detalle" header="DETALLE"></Column>
        <Column :class="'text-right'" field="ent_cantidad" header="E_CANT.">
          <template #body="data">
            <div>
              {{ sinDecimal(data.data.ent_cantidad) }}
            </div>
          </template>
        </Column>
        <Column :class="'text-right'" field="ent_costo" header="E_COSTO">
          <template #body="data">
            <div>
              {{ convertirNumeroGermanicFormat(data.data.ent_costo) }}
            </div>
          </template>
        </Column>
        <Column :class="'text-right'" field="ent_total" header="E_TOTAL">
          <template #body="data">
            <div>
              {{ convertirNumeroGermanicFormat(data.data.ent_total) }}
            </div>
          </template>
        </Column>
        <Column :class="'text-right'" field="sal_cantidad" header="S_CANT">
          <template #body="data">
            <div>
              {{ sinDecimal(data.data.sal_cantidad) }}
            </div>
          </template>
        </Column>
        <Column :class="'text-right'" field="sal_costo" header="S_COSTO">
          <template #body="data">
            <div>
              {{ convertirNumeroGermanicFormat(data.data.sal_costo) }}
            </div>
          </template>
        </Column>
        <Column :class="'text-right'" field="sal_total" header="S_TOTAL">
          <template #body="data">
            <div>
              {{ convertirNumeroGermanicFormat(data.data.sal_total) }}
            </div>
          </template>
        </Column>
        <Column
          :class="'text-right'"
          field="saldo_cantidad"
          header="EXISTENCIA"
        >
          <template #body="data">
            <div>
              {{ sinDecimal(data.data.saldo_cantidad) }}
            </div>
          </template>
        </Column>
        <Column :class="'text-right'" field="saldo_total" header="SALDO_TOTAL">
          <template #body="data">
            <div>
              {{ convertirNumeroGermanicFormat(data.data.saldo_total) }}
            </div>
          </template>
        </Column>
        <Column :class="'text-right'" field="cpp" header="CPP">
          <template #body="data">
            <div>
              {{ convertirNumeroGermanicFormat(data.data.cpp) }}
            </div>
          </template>
        </Column>
        <Column field="usuario" header="USUARIO"></Column>
      </DataTable>
    </div>
  </div>
</template>
<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "@/service/ProductService";
import KardexService from "@/service/KardexService";
import SucursalService from "@/service/SucursalService";

export default {
  data() {
    return {
      kardexs: [],
      productoSelected: null,
      productosListadoFiltrado: [],
      enviando: false,
      generando_pdf: false,
      exportando: false,
      fecha_inicio: null,
      fecha_fin: null,
      buscar: {
        global: { matchMode: FilterMatchMode.CONTAINS, value: null },
      },
      sucursales: [{ id: 0, nombre: "TODAS" }],
      sucursalSelected: 0,
      codigo_producto: null,
      stockClass: (data) => {
        if (data <= 0) {
          return "outofstock";
        } else if (data <= 10) {
          return "lowstock";
        } else {
          return "instock";
        }
      },
    };
  },
  productService: null,
  kardexService: null,
  sucursalService: null,
  created() {
    this.productService = new ProductService();
    this.kardexService = new KardexService();
    this.sucursalService = new SucursalService();
  },
  mounted() {
    this.cargarSucursales();
  },
  methods: {
    sinDecimal(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 0,
      }).format(numero);
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    cargarSucursales() {
      this.sucursalService
        .getSucursalesAll()
        .then((response) => {
          response.forEach((sucursal) => {
            this.sucursales.push(sucursal);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    exportKardexs() {
      if (this.kardexs.length > 0) {
        this.exportando = true;
        let datos = {
          producto_id: this.productoSelected ? this.productoSelected.id : null,
          fecha_inicio: this.fecha_inicio,
          fecha_fin: this.fecha_fin,
          sucursal_id:
            this.sucursalSelected == 0 ? null : this.sucursalSelected,
          codigo_producto: this.codigo_producto ? this.codigo_producto : null,
        };
        this.kardexService.exportKardexs(datos).then(() => {
          this.exportando = false;
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: "Se exportó correctamente",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "No hay datos para exportar",
          life: 3000,
        });
      }
    },
    generarPDF() {
      if (this.kardexs.length > 0) {
        this.generando_pdf = true;
        let datos = {
          producto_id: this.productoSelected ? this.productoSelected.id : null,
          fecha_inicio: this.fecha_inicio,
          fecha_fin: this.fecha_fin,
          sucursal_id:
            this.sucursalSelected == 0 ? null : this.sucursalSelected,
          codigo_producto: this.codigo_producto ? this.codigo_producto : null,
        };
        this.kardexService.generarPDF(datos).then(() => {
          this.generando_pdf = false;

          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: "Se generó el PDF correctamente",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "No hay datos para generar el PDF",
          life: 3000,
        });
      }
    },
    buscarProducto(event) {
      setTimeout(() => {
        this.productService
          .buscarXNombre({
            texto: event.query,
            sucursal_id: this.sucursalSelected,
          })
          .then((response) => {
            this.productosListadoFiltrado = [...response.productos];
          })
          .catch((error) => console.log(error));
      }, 250);
    },
    BuscarFiltro() {
      // Validar que se haya seleccionado un producto
      if (!this.productoSelected && !this.codigo_producto) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe seleccionar un producto",
          life: 3000,
        });
        return;
      }
      this.kardexs = [];
      this.enviando = true;
      this.cargarProductosFiltros();
    },
    cargarProductosFiltros() {
      let datos = {
        producto_id: this.productoSelected ? this.productoSelected.id : null,
        fecha_inicio: this.fecha_inicio,
        fecha_fin: this.fecha_fin,
        sucursal_id: this.sucursalSelected,
        codigo_producto: this.codigo_producto ? this.codigo_producto : null,
      };
      this.kardexService
        .filtrarKardexs(datos)
        .then((response) => {
          this.kardexs = response.kardexs;
          this.enviando = false;
        })
        .catch(() => {
          this.enviando = false;
        });
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-fondo {
  background-color: #7bffae;
  border-radius: 5px;
  border: 1px solid #002236;
  padding: 5px;
}
.outofstock {
  font-weight: 700;
  color: #000000;
  background-color: #ff5252;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.lowstock {
  font-weight: 700;
  color: #000000;
  background-color: #fbc02d;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.instock {
  font-weight: 700;
  color: #000000;
  background-color: #7bffae;
  padding: 0 0.5em;
  border-radius: 0.5em;
}
.clase-breadcrumb {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
}
</style>
